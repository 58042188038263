export const currency = `
    currency {
        __typename
        id
        name
        rate
    }
`;
export const moneyFragment = `
    amount
    ${currency}
`;

export const price = `
    price {
        ${moneyFragment}
        hidePrice
    }
    price_amount_usd
`;

const subsidiaries = `
    subsidiaries {
        id
        name
        masked_phone
        address
        office_hours
    }
`;
export const owner = `
    owner {
        __typename
        id
        name
        masked_phone
        has_whatsapp
        active
        logo
        name
        inmoLink
        inmoPropsLink
        inmofull
        type
        ... on RealEstateAgent {
            id
            name
            masked_phone
            address
            logo
            inmoLink
          	inmoPropsLink
            ${subsidiaries}
        }
        ... on Developer {
            id
            name
            logo
            masked_phone
            inmoLink
          	inmoPropsLink
            ${subsidiaries}
        }
    }
`;
export const estate = `
    estate {
        __typename
        id
        name
    }
`;
export const neighborhood = `
    neighborhood {
        __typename
        id
        name
        ${estate}
        lat
        long
    }
`;
export const seller = `
    seller {
        __typename
        id
        individual
        type
        name
        role
        masked_phone
    }
`;
export const technicalSheet = `
    technicalSheet {
        field
        value
        text
    }
`;

export const property = `
        __typename
        id
        title
        typeID
        address
        showAddress
        country_id
        description
        code
        code2
        finances
        grouped_ids
        img
        ${price}
        ${owner}
        ${neighborhood}
        ${estate}
        ${seller}
        ${technicalSheet}
        image_count
        file_count
        imgSize
        link
        property_type {
            id
            name
        }
        operation_type {
            id
            name
        }
        barter
        facilities {
            __typename
            id
            name
            group
        }
        m2
        hectares
        created_at
        updated_at
        latitude
        images {
            id
            image
            tag
        }
        files {
            id
            file
            title
        }
        source
        project {
            id
            id_form
            title
            facilities
            description
            tour3d
            minM2
            video
            files{
                id 
                file 
                title
            }
            minPrice {
                amount
                currency {
                    name
                    rate
                }
            }
            address
            link
            title
            bathrooms
            bedrooms
            rooms
            bathrooms_listing
            bedrooms_listing
            images {
                id
                image
                tag
            }
            neighborhood {
                __typename
                id
                name
                estate {
                    __typename
                    id
                    name
                }
                lat
                long
            }
            estate {
                __typename
                id
                name
            }
            socialMediaLinks {
                slug
                name
                url
                icon
            }
        }
        img
        color
        longitude
        pausd
        pointType
        zoom
        youtube
        highlight
        active
        deleted
        relevance
        construction_year
        notes
        sold
        soldDate
        discount
        draft
        sign
        guarantee
        facilitiesNotApply
        commonExpenses { 
            ${moneyFragment} 
            hidePrice
        }
        price_variation {
            difference
            percentage
            ${moneyFragment}
        }
        ceCurrencyID
        highlightDate
        socialMediaLinks {
            slug
            name
            url
            icon
            order
        }
        m2Built
        garage
        office
        dispositionID
        bathrooms
        bedrooms
        rooms
        seaview
        livingPlace
        condominium
        frontLength
        floorsCount
        m2apto
        apartmentsPerFloor
        floor
        neighborhood_id
        estate_id
        farmhouse
        allowedHeight
        hectares
        isFavorite
        isExternal
        guests
        seasons {
            id
            days_amount
            name
            slug
            date_begin
            date_end
            rental_data {
                property_id
                season_id
                price
                currency {
                    name
                }
                minimum_stay
            }
        }
        occupancies {
            id
            date_begin
            date_end
            property_id
        }
        temporal_price
        temporal_currency {
            id
            name
            rate
        }
        seaDistanceName
        tour3d
`;

export const filter_options = `
    id
    name
    options
`;
